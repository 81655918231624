"use client";

import Link from "next/link";
import notFoundImage from "@/assets/images/landing/hero.webp";

export default function NotFound() {
  return (
    <div
      className="flex min-h-[100dvh] w-full flex-col items-center justify-center gap-16 overflow-x-hidden bg-cover bg-center bg-no-repeat px-4 ph:px-24 md:px-48 lg:px-72 xl:px-96"
      style={{
        backgroundImage: `linear-gradient(rgba(25, 25, 25, 0.75), rgba(25, 25, 25, 0.75)), url(${notFoundImage.src})`,
      }}
    >
      <div className="flex flex-col items-center justify-center text-center">
        <span className="text-md mb-2 uppercase tracking-wider text-white ph:mb-4 ph:text-lg">
          Erreur 404
        </span>

        <h1 className="mb-[-1rem] text-4xl uppercase leading-[0.9] tracking-wider text-white xs:text-5xl ph:mb-[-1.5rem] ph:text-6xl sm:text-7xl md:mb-[-2rem] md:text-8xl lg:text-9xl">
          Page non <br /> trouvée
        </h1>

        <p className="text-md mt-16 max-w-lg text-white ph:text-lg">
          La page que vous recherchez n&apos;existe pas ou a été déplacée. Nous
          vous invitons à retourner à l&apos;accueil ou à prendre rendez-vous
          dans l&apos;un de nos salons.
        </p>

        <div className="mt-12 flex flex-col gap-6 sm:flex-row">
          <Link
            href="/"
            className="group relative overflow-hidden rounded-md bg-white px-8 py-3"
          >
            <span className="relative z-10 flex items-center justify-center font-medium text-primary transition-colors group-hover:text-white">
              <svg
                className="mr-2 h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                ></path>
              </svg>
              Retourner à l&apos;accueil
            </span>
            <div className="absolute bottom-0 left-0 h-full w-0 bg-primary transition-all duration-300 ease-out group-hover:w-full"></div>
          </Link>

          <Link
            href="/reservation"
            className="group relative overflow-hidden rounded-md border-2 border-white px-8 py-3"
          >
            <span className="relative z-10 flex items-center justify-center font-medium text-white transition-colors group-hover:text-gray-900">
              <svg
                className="mr-2 h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                ></path>
              </svg>
              Réserver un rendez-vous
            </span>
            <div className="absolute bottom-0 left-0 h-full w-0 bg-white transition-all duration-300 ease-out group-hover:w-full"></div>
          </Link>
        </div>
      </div>
    </div>
  );
}
