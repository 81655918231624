"use client";

import { Navbar, NavbarBrand } from "@nextui-org/navbar";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState, useEffect, useRef } from "react";

const navLinks = [
  { name: "Accueil", href: "/" },
  { name: "Le salon", href: "/le-salon" },
  { name: "Prestations", href: "/prestations" },
  { name: "Collections", href: "/collections" },
  { name: "Carte cadeau", href: "/carte-cadeau" },
  { name: "Blog", href: "/blog" },
  { name: "Contact", href: "/contact" },
];

const rdvLink = {
  name: "Prendre RDV",
  href: "/reservation",
};

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPassedHero, setIsPassedHero] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const navbarRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();

  const isAuthPage =
    pathname === "/login" ||
    pathname === "/forgot-password" ||
    pathname === "/dashboard" ||
    pathname === "/dashboard/events" ||
    pathname === "/dashboard/blog";
  const isHomePage = pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY < 50);

      if (isHomePage) {
        const heroSection = document.getElementById("hero-section");
        if (heroSection) {
          const heroRect = heroSection.getBoundingClientRect();
          const scrollPosition = window.scrollY;
          setIsPassedHero(scrollPosition > heroRect.height - 100);
        }
      } else {
        setIsPassedHero(!isAtTop);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "";
    };
  }, [isMenuOpen, isAtTop, isHomePage]);

  const handleNavItemClick = () => {
    setIsMenuOpen(false);
  };

  const navbarClasses = `
    px-4 ph:px-24 md:px-48 lg:px-72 xl:px-96 py-2 border-none duration-300 ease-in-out fixed top-0 left-0 right-0 z-40
    ${
      isMenuOpen
        ? "bg-transparent"
        : isAuthPage
          ? "bg-white text-black"
          : isPassedHero
            ? "bg-white text-black"
            : isHomePage
              ? "bg-transparent text-white"
              : isAtTop
                ? "bg-transparent text-white"
                : "bg-white text-black"
    }
  `;

  const getLogoColor = () => {
    if (isMenuOpen) return isPassedHero ? "text-black" : "text-white";
    if (isAuthPage) return "text-black";
    if (isPassedHero) return "text-black";
    return "text-white";
  };

  const BurgerMenu = () => (
    <button
      title="Menu"
      className="relative z-50 h-6 w-6"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      aria-label="Menu de navigation"
    >
      <div className="flex h-full w-full flex-col items-end justify-center gap-1.5">
        <span
          className={`block h-[1px] w-6 transition-all duration-300 ${
            isPassedHero ? "bg-black" : isAuthPage ? "bg-black" : "bg-white"
          }`}
        />
        <span
          className={`block h-[1px] w-4 transition-all duration-300 ${
            isPassedHero ? "bg-black" : isAuthPage ? "bg-black" : "bg-white"
          }`}
        />
        <span
          className={`block h-[1px] w-6 transition-all duration-300 ${
            isPassedHero ? "bg-black" : isAuthPage ? "bg-black" : "bg-white"
          }`}
        />
      </div>
    </button>
  );

  return (
    <>
      <div
        className={`fixed inset-0 z-20 bg-black/40 transition-opacity duration-300 ease-in-out ${
          isMenuOpen ? "opacity-100" : "pointer-events-none opacity-0"
        }`}
        onClick={() => setIsMenuOpen(false)}
      />

      <Navbar
        ref={navbarRef}
        className={navbarClasses}
        isBordered={!isPassedHero && !isMenuOpen}
        shouldHideOnScroll={false}
        isMenuOpen={isMenuOpen}
        isBlurred={false}
      >
        <NavbarBrand>
          <Link href="/">
            <span className={`font-cormorant text-3xl ${getLogoColor()}`}>
              AG
            </span>
          </Link>
        </NavbarBrand>
        <BurgerMenu />
      </Navbar>
      <div
        ref={menuRef}
        className={`fixed right-0 top-0 z-40 h-screen w-80 transform bg-white transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="mt-24 flex flex-col gap-8 px-12 text-end">
          <button
            title="Fermer le menu"
            onClick={() => setIsMenuOpen(false)}
            className="mb-4 ml-auto h-6 w-6"
            aria-label="Fermer le menu"
          >
            <span className="absolute block h-[1px] w-6 rotate-45 bg-black" />
            <span className="absolute block h-[1px] w-6 -rotate-45 bg-black" />
          </button>

          {navLinks.map((item) => (
            <Link
              key={item.name}
              href={item.href}
              className="w-full text-end text-xl font-extralight hover:text-gold screen-h700:text-2xl"
              onClick={handleNavItemClick}
            >
              {item.name}
            </Link>
          ))}

          <div className="flex justify-end">
            <hr className="my-4 h-[1px] w-2/3 border-none bg-primary" />
          </div>

          <Link
            href={rdvLink.href}
            key={rdvLink.name}
            className="text-xl font-extralight hover:text-gold screen-h700:text-2xl"
            onClick={handleNavItemClick}
          >
            {rdvLink.name}
          </Link>
        </div>
      </div>
    </>
  );
};
