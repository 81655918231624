import { type ServiceSection } from "./types";
import { soinBalinetService } from "./services/soin-balinet";
import { visagisteService } from "./services/visagiste";
import { privatisationService } from "./services/privatisation";

export interface Services {
  soinBalinet: ServiceSection;
  visagiste: ServiceSection;
  privatisation: ServiceSection;
}

export const ServicesContent: Services = {
  soinBalinet: soinBalinetService,
  visagiste: visagisteService,
  privatisation: privatisationService,
};
