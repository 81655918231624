import { CollectionData } from "./collections";

import casadelgrey1 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-01.jpg";
import casadelgrey2 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-02.jpg";
import casadelgrey3 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-03.jpg";
import casadelgrey4 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-04.jpg";
import casadelgrey5 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-05.jpg";
import casadelgrey6 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-06.jpg";
import casadelgrey7 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-07.jpg";
import casadelgrey8 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-08.jpg";
import casadelgrey9 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-09.jpg";
import casadelgrey10 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-10.jpg";
import casadelgrey11 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-11.jpg";
import casadelgrey12 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-12.jpg";
import casadelgrey13 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-13.jpg";
import casadelgrey14 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-14.jpg";
import casadelgrey15 from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-15.jpg";

import collectionsBanner from "@/assets/images/banners/collections-banner.webp";

export const casaDelGreyDescriptionParagraphs = [
  "Casa Del Grey célèbre l'élégance méditerranéenne avec une palette audacieuse de rouges profonds et des lignes sophistiquées. Chaque pièce reflète l'opulence et la chaleur des soirées d'été dans des lieux somptueux.",
  "Les coupes impeccables et les textures riches s'unissent pour créer une esthétique à la fois intemporelle et contemporaine. Des silhouettes audacieuses, accentuées par des détails luxueux, incarnent une confiance affirmée.",
  "Cette collection est une ode à la passion et au raffinement, transformant chaque tenue en une déclaration visuelle puissante. Casa Del Grey réinvente le glamour avec une intensité captivante et une élégance sans compromis.",
];

export const casaDelGreyCollection: CollectionData = {
  id: "2022-casa-del-grey",
  title: "Casa Del Grey",
  descriptionParagraphs: casaDelGreyDescriptionParagraphs,
  images: [
    casadelgrey1,
    casadelgrey2,
    casadelgrey3,
    casadelgrey4,
    casadelgrey5,
    casadelgrey6,
    casadelgrey7,
    casadelgrey8,
    casadelgrey9,
    casadelgrey10,
    casadelgrey11,
    casadelgrey12,
    casadelgrey13,
    casadelgrey14,
    casadelgrey15,
  ],
  date: "07/22",
  bannerImage: collectionsBanner,
};
