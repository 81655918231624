import visagisteImg from "@/assets/images/prestations/visagiste.jpg";
import coiffuremariage from "@/assets/images/prestations/visagiste-intro.jpg";
import { type ServiceSection } from "../types";
import { pastWorkImages } from "./past-work-images";

export const visagisteService: ServiceSection = {
  id: "visagiste",
  bannerImage: visagisteImg,
  bannerTitle: "Coiffeur Visagiste",
  keywords: [
    "coiffeur visagiste villejuif",
    "visagiste ville d'avray",
    "meilleur coiffeur visagiste 94",
    "coiffeur conseil villejuif",
    "visagiste val de marne",
    "coupe personnalisée",
  ],
  firstSection: {
    imagePath: coiffuremariage.src,
    title: "Coiffeur Visagiste à Villejuif (94800) et Ville d'Avray (92410)",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Découvrez l'expertise de nos coiffeurs visagistes dans nos salons de Villejuif et Ville d'Avray. Nos experts créent des coupes personnalisées qui subliment votre visage et révèlent votre personnalité.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Envie d'une transformation capillaire qui révèle votre personnalité et l'harmonie de votre visage ? Notre service Visagiste est fait pour vous.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Dans nos salons Alexandra Grey, nos experts étudient la forme de votre visage, la texture de vos cheveux et votre style pour proposer une coupe et un look sur mesure. Le but : révéler votre beauté naturelle, sans artifices.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Notre promesse", isBold: true },
          {
            text: " : une coupe parfaitement adaptée à votre morphologie, vous permettant d'exprimer qui vous êtes, simplement et élégamment.",
          },
        ],
      },
    ],
  },
  secondSection: {
    title: "Notre prestation Visagiste",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "De la consultation initiale à la coupe finale, notre approche est entièrement personnalisée. Nous prenons le temps de comprendre vos préférences, vos besoins, et de proposer des solutions adaptées.",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "Les étapes clés :" }],
        items: [
          "Analyse détaillée : étude des traits, de la forme du visage, et de la carnation",
          "Propositions ciblées : coupes, couleurs et techniques adaptées pour sublimer vos atouts",
          "Réalisation experte : mise en forme finale et conseils pour entretenir votre nouveau style",
        ],
      },
    ],
  },
  thirdSection: {
    title: "Pourquoi faire appel à un visagiste ?",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Un visagiste est plus qu'un simple coiffeur. C'est un expert capable de traduire votre personnalité en une coupe qui s'intègre harmonieusement à votre visage. Fini les coiffures génériques : place à un style unique.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour les visages ronds, nous équilibrons les volumes, pour les visages carrés nous adoucissons les angles, et ainsi de suite. Chaque morphologie a sa solution.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Dans votre salon Alexandra Grey, vous bénéficiez d'un accompagnement personnalisé, de conseils avisés et de techniques professionnelles pour un résultat authentique, en parfaite adéquation avec votre image.",
          },
        ],
      },
    ],
  },
  fourthSection: {
    title: "TRAVAUX ANTÉRIEURS",
    images: pastWorkImages,
  },
};
