"use client";

import { HeroSection } from "@/(landing-page)/sections/HeroSection";
import { CTA } from "@/components/sections/CTA";
import { PrestationsSection } from "@/(landing-page)/sections/PrestationsSection";
import { CollectionsSection } from "@/(landing-page)/sections/CollectionsSection";
import { MonthlyEventSection } from "@/(landing-page)/sections/MonthlyEventSection";
import { ReviewsSection } from "@/components/sections/reviews/ReviewsSection";
import { FindUsSection } from "@/components/sections/find-us/FindUsSection";
import { OurSalonsHours } from "@/components/sections/nos-salons-hours/OurSalonsHours";
import { GiftCardSection } from "@/components/sections/GiftCardSection";

import { useRouter } from "next/navigation";
import ScrollRestoration from "@/utils/hooks/useScrollRestoration";
import { SeoSection } from "@/components/sections/SeoSection";
import { ContactCTA } from "@/components/sections/Contact-CTA";

const HomePage = () => {
  const router = useRouter();
  ScrollRestoration();

  return (
    <main className="flex min-h-screen max-w-[100vw] flex-col items-center justify-between">
      <HeroSection />
      <CTA />
      <PrestationsSection />
      <GiftCardSection />
      {/* <MonthlyEventSection /> */}
      <CollectionsSection />
      <ContactCTA />
      <ReviewsSection />
      <FindUsSection />
      <OurSalonsHours />
      <SeoSection />
    </main>
  );
};

export default HomePage;
