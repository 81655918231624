import { CollectionData } from "./collections";

import splash1 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-01.jpg";
import splash2 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-02.jpg";
import splash3 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-03.jpg";
import splash4 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-04.jpg";
import splash5 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-05.jpg";
import splash6 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-06.jpg";
import splash7 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-07.jpg";
import splash8 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-08.jpg";
// missing 9
import splash10 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-10.jpg";
import splash11 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-11.jpg";
import splash12 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-12.jpg";
import splash13 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-13.jpg";
import splash14 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-14.jpg";
import splash15 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-15.jpg";
import splash16 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-16.jpg";
import splash17 from "/public/images/collections/2022-splash/ALEXANDRA-GREY-SPLASH-17.jpg";

export const splashDescriptionParagraphs = [
  "Splash s'inspire des profondeurs aquatiques, combinant textures fluides et nuances océaniques. Chaque look évoque la fraîcheur et la puissance de l'eau, traduisant une esthétique futuriste et audacieuse.",
  "Les silhouettes épurées et les matières translucides rappellent le mouvement des vagues, tandis que des détails métalliques ajoutent une touche d'avant-garde. La collection reflète une fusion entre nature et modernité.",
  "Avec Splash, l'élégance plonge dans un univers aquatique, capturant l'essence de la fluidité et de l'énergie en constante transformation. Une véritable immersion dans un luxe innovant et vibrant.",
];

export const splashCollection: CollectionData = {
  id: "2022-splash",
  title: "Collection Splash",
  descriptionParagraphs: splashDescriptionParagraphs,
  images: [
    splash1,
    splash2,
    splash3,
    splash4,
    splash5,
    splash6,
    splash7,
    splash8,
    splash10,
    splash11,
    splash12,
    splash13,
    splash14,
    splash15,
    splash16,
    splash17,
  ],
  date: "03/22",
  bannerImage: splash1,
};
