import soinBalinet from "@/assets/images/prestations/soin-balinet.jpg";
import soinBalinetIntro from "@/assets/images/prestations/soin-balinet-intro.webp";
import { type ServiceSection } from "../types";
import { pastWorkImages } from "./past-work-images";

export const soinBalinetService: ServiceSection = {
  id: "soin-balinet",
  bannerImage: soinBalinet,
  bannerTitle: "Soin Balinet",
  keywords: [
    "soin cheveux villejuif",
    "traitement capillaire ville d'avray",
    "soin balinet val de marne",
    "soin cheveux 94800",
    "coiffeur soin villejuif",
    "salon coiffure soin 92410",
  ],
  firstSection: {
    imagePath: soinBalinetIntro.src,
    title:
      "Soin Balinet : Expert Soin Cheveux à Villejuif (94) et Ville d'Avray (92)",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le Soin Balinet, exclusif aux salons Alexandra Grey de Villejuif et Ville d'Avray, est une expérience capillaire haut de gamme. Nos experts subliment votre chevelure avec ce soin professionnel reconnu dans le Val-de-Marne et les Hauts-de-Seine.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Dans nos salons Alexandra Grey en Ile de France, nos experts capillaires analysent votre chevelure et vos besoins spécifiques. Le Soin Balinet combine techniques de massage du cuir chevelu, soins nourrissants et finitions personnalisées pour un résultat sur mesure.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Notre promesse", isBold: true },
          {
            text: " : une chevelure revitalisée, lumineuse et pleine de caractère, pour vous sentir confiant(e) et impeccable.",
          },
        ],
      },
    ],
  },
  secondSection: {
    title: "Notre prestation Soin Balinet",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Avec le Soin Balinet, chaque étape est pensée pour révéler la beauté naturelle de vos cheveux. Peu importe votre type de chevelure, nous adaptons nos soins et techniques pour garantir un résultat exceptionnel.",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "Nos étapes incluent :" }],
        items: [
          "Diagnostic personnalisé : identification de vos besoins et attentes",
          "Soin profond : application de produits nourrissants et hydratants pour une brillance optimale",
          "Finition sur mesure : coiffage et conseils personnalisés pour prolonger les effets du soin",
        ],
      },
    ],
  },
  thirdSection: {
    title: "Pourquoi choisir le Soin Balinet ?",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Le Soin Balinet va au-delà d'un simple traitement capillaire. Il s'agit d'un rituel qui apporte détente, confort et élégance. Nos experts sont formés pour vous offrir une expérience complète, adaptée à la forme de votre visage, la nature de vos cheveux et votre style de vie.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour les cheveux longs, le Soin Balinet apporte souplesse, douceur et éclat. Pour les coupes plus courtes, c'est l'occasion de révéler la texture et le caractère de votre chevelure.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Dans votre salon Alexandra Grey, nous utilisons des produits haut de gamme, sélectionnés pour leur efficacité et leur respect du cheveu, garantissant ainsi un résultat durable.",
          },
        ],
      },
    ],
  },
  fourthSection: {
    title: "TRAVAUX ANTÉRIEURS",
    images: pastWorkImages,
  },
};
