import { CollectionData } from "./collections";

import lightgrey1 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-01.jpg";
import lightgrey2 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-02.jpg";
import lightgrey3 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-03.jpg";
import lightgrey4 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-04.jpg";
import lightgrey5 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-05.jpg";
import lightgrey6 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-06.jpg";
import lightgrey7 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-07.jpg";
import lightgrey8 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-08.jpg";
import lightgrey9 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-09.jpg";
import lightgrey10 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-10.jpg";
import lightgrey11 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-11.jpg";
import lightgrey12 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-12.jpg";
import lightgrey13 from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-13.jpg";

export const lightGreyDescriptionParagraphs = [
  "Light Grey incarne la douceur et la lumière, à travers des teintes pastel et des textures aériennes. Chaque création évoque une élégance bohème, ancrée dans la simplicité et la poésie de la nature.",
  "Les motifs floraux et les matières légères s’unissent pour offrir une esthétique délicate et intemporelle. Cette collection invite à une promenade dans un univers de sérénité, où chaque détail raconte une histoire.",
  "Light Grey célèbre une beauté pure et naturelle, mêlant subtilité et raffinement pour des looks qui respirent la fraîcheur et l'harmonie. Une ode à la lumière et à la douceur des beaux jours.",
];

export const lightGreyCollection: CollectionData = {
  id: "2021-light-grey",
  title: "Collection Light Grey",
  descriptionParagraphs: lightGreyDescriptionParagraphs,
  images: [
    lightgrey1,
    lightgrey2,
    lightgrey3,
    lightgrey4,
    lightgrey5,
    lightgrey6,
    lightgrey7,
    lightgrey8,
    lightgrey9,
    lightgrey10,
    lightgrey11,
    lightgrey12,
    lightgrey13,
  ],
  date: "04/21",
  bannerImage: lightgrey1,
};
