import { CollectionData } from "./collections";

import naturalgrey1 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-01.jpg";
import naturalgrey2 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-02.jpg";
import naturalgrey3 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-03.jpg";
import naturalgrey4 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-04.jpg";
import naturalgrey5 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-05.jpg";
import naturalgrey6 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-06.jpg";
import naturalgrey7 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-07.jpg";
import naturalgrey8 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-08.jpg";
import naturalgrey9 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-09.jpg";
import naturalgrey10 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-10.jpg";
import naturalgrey11 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-11.jpg";
import naturalgrey12 from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-12.jpg";

export const naturalGreyDescriptionParagraphs = [
  "La collection Natural Grey d'octobre 2023 incarne une fusion entre nature brute et sophistication urbaine. Chaque création explore des textures organiques qui rappellent les paysages naturels tout en jouant avec des formes modernes et audacieuses.",
  "Avec des éléments inspirés de la végétation sauvage et des couleurs terreuses, chaque pièce traduit une histoire unique, mêlant force et délicatesse. Les coiffures sont conçues comme des sculptures vivantes, évoquant la puissance de la nature en mouvement.",
  "Cette collection célèbre une élégance intemporelle, où les matières naturelles se rencontrent dans des compositions artistiques audacieuses. Natural Grey redéfinit le luxe à travers une vision profondément enracinée dans l'authenticité et l'avant-garde.",
];

export const naturalGreyCollection: CollectionData = {
  id: "2023-natural-grey",
  title: "Collection Natural Grey",
  descriptionParagraphs: naturalGreyDescriptionParagraphs,
  images: [
    naturalgrey1,
    naturalgrey2,
    naturalgrey3,
    naturalgrey4,
    naturalgrey5,
    naturalgrey6,
    naturalgrey7,
    naturalgrey8,
    naturalgrey9,
    naturalgrey10,
    naturalgrey11,
    naturalgrey12,
  ],
  date: "10/23",
  bannerImage: naturalgrey1,
};
