export interface Salon {
  id: string;
  name: string;
  address: {
    street: string;
    postalCode: string;
    city: string;
  };
  hours: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
  };
  phone: string;
  planityLink: string;
}

export const salons: Salon[] = [
  {
    id: "choisy-le-roi",
    name: "CHOISY LE ROI",
    address: {
      street: "2, place de l'église",
      postalCode: "94600",
      city: "Choisy le Roi",
    },
    hours: {
      monday: "Fermé",
      tuesday: "10h - 19h",
      wednesday: "9h30 - 19h",
      thursday: "9h30 - 19h",
      friday: "9h30 - 19h",
      saturday: "9h - 18h",
      sunday: "Fermé",
    },
    phone: "01 48 52 90 50",
    planityLink: "https://www.planity.com/alexandra-grey-choisy-le-roi-94600",
  },
  {
    id: "villejuif",
    name: "VILLEJUIF",
    address: {
      street: "61, Rue Jean Jaurès",
      postalCode: "94800",
      city: "Villejuif",
    },
    hours: {
      monday: "10h30 - 18h",
      tuesday: "9h30 - 19h",
      wednesday: "9h30 - 19h",
      thursday: "9h30 - 19h",
      friday: "9h30 - 19h",
      saturday: "9h30 - 19h",
      sunday: "Fermé",
    },
    phone: "01 49 58 22 60",
    planityLink: "https://www.planity.com/alexandra-grey-villejuif-94800",
  },
  {
    id: "au-grey-des-couleurs",
    name: "AU GREY DES COULEURS",
    address: {
      street: "1 Rue Eugène Varlin",
      postalCode: "94800",
      city: "Villejuif",
    },
    hours: {
      monday: "Fermé",
      tuesday: "9h30 - 19h",
      wednesday: "9h30 - 19h",
      thursday: "9h30 - 19h",
      friday: "9h30 - 19h",
      saturday: "9h30 - 19h",
      sunday: "Fermé",
    },
    phone: "01 46 78 60 67",
    planityLink: "https://www.planity.com/au-grey-des-couleurs-94800-villejuif",
  },
  {
    id: "ville-davray",
    name: "VILLE D'AVRAY",
    address: {
      street: "28, Rue de Saint Cloud",
      postalCode: "92410",
      city: "Ville d'Avray",
    },
    hours: {
      monday: "Fermé",
      tuesday: "9h30 - 19h",
      wednesday: "9h30 - 19h",
      thursday: "9h30 - 19h",
      friday: "9h30 - 19h",
      saturday: "9h30 - 19h",
      sunday: "Fermé",
    },
    phone: "01 47 09 99 45",
    planityLink: "https://www.planity.com/alexandra-grey-ville-davray-92410",
  },
];
