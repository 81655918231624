import imanezouin from "@/assets/images/reviews/imane-zouin.png";
import anaispriccaz from "@/assets/images/reviews/anais-priccaz.png";
import virginie from "@/assets/images/reviews/virginie.png";
import channelhaze from "@/assets/images/reviews/channel-haze.png";
import celineflouret from "@/assets/images/reviews/celine-flouret.png";
import baratlimoez from "@/assets/images/reviews/baratli-moez.png";
import theresabonatesta from "@/assets/images/reviews/theresa-bonatesta.png";
import clemenceeisenberg from "@/assets/images/reviews/clemence-eisenberg.png";
export interface ReviewData {
  id: string;
  name: string;
  imagePath: string;
  review: string;
  postedAt: string;
}

export const reviewsData: ReviewData[] = [
  {
    id: "anais-priccaz",
    name: "Anais Priccaz",
    imagePath: anaispriccaz.src,
    review:
      "Toujours satisfaite comme depuis que je viens ! Accueil chaleureux, avec la proposition d’un petit café/thé, les coiffeurs (ses) savent vous mettre à l’aise ! L’écoute, les conseils des coiffeurs ainsi que leur professionnalisme sont leur priorités Ce n’est pas la première fois dans ce salon et ce ne sera pas la dernière Je vous recommande vivement ce salon de coiffure !! Merci à toute l’équipe Grey 😊",
    postedAt: "il y a un mois",
  },
  {
    id: "virginie",
    name: "Virginie",
    imagePath: virginie.src,
    review:
      "Une jolie découverte. Déjà pour commencer, personnel agréable, souriant et salon propre. J’y ai effectué une coloration et on m’a proposé un rituel balinais. Mais wahou, j’ai été conquise par ce massage, pas comme les autres du cuir chevelure qui prends aussi la nuque et une partie du visage (mesdames, maquillage léger ce jour là 😁). C’est vraiment un moment de bien être à part entière. Alors merci pour ce moment de détente, de convivialité et de beauté.",
    postedAt: "il y a 1 mois",
  },
  {
    id: "channel-haze",
    name: "Channel Haze",
    imagePath: channelhaze.src,
    review:
      "Adorable, pétillante écoute son client et se préoccupe de bien faire les choses, m a fait ma couleur ton sur ton comme je le voulais, j’ai eu un sublime brushing merci beaucoup un grand merci à l’équipe ♥️♥️",
    postedAt: "il y a 5 mois",
  },
  {
    id: "celine-flouret",
    name: "Céline Flouret",
    imagePath: celineflouret.src,
    review:
      "Équipe très accueillante, qui ne manque pas d'être aux petits soins de ses clients, petit café collation c'est agréable. Je suis vraiment ravie du résultat suite à un carnage que j'ai eu chez un autre coiffeur. Alexandra a su me redonner le sourire en retrouvant une couleur plus que satisfaisant. Merci à elle et son équipe pour la prestation de qualité. Aujourd'hui j'y suis retournée et j'en suis toujours ravie du résultat. Vraiment des professionnelles.",
    postedAt: "il y a 2 ans",
  },
  {
    id: "baratli-moez",
    name: "Baratli Moez",
    imagePath: baratlimoez.src,
    review:
      "Équipe adorable et aux petits soins. Ambiance chaleureuse et familiale. Merci à l'ensemble de l'équipe et tout particulièrement à Anissa qui est aussi professionnelle et consciencieuse qu'elle est gentille et bienveillante. Un énorme merci pour ce magnifique travail. Je recommande ce salon à toutes et à tous. ♥️",
    postedAt: "il y a 10 mois",
  },
  {
    id: "imane-zouin",
    name: "Imane Zouin",
    imagePath: imanezouin.src,
    review:
      "Très heureuse de mon brushing chez Alexandra Grey. Un grand merci à Nöel pour son professionnalisme ! Cela fait très longtemps que je ne suis pas ressorti d’un salon satisfaite d’une prestation. Je reviendrais :) Merci Noël !",
    postedAt: "il y a un an",
  },
  {
    id: "theresa-bonatesta",
    name: "Theresa Bonatesta",
    imagePath: theresabonatesta.src,
    review:
      "Cela fait plus de 10 ans que je vais me faire couper les cheveux dans ce salon ainsi que ma fille. J'ai toujours été satisfaite et pourtant je ne suis pas toujours coupée par la même personne et je fais des coupes très courtes ce qui n'est pas simple . L'équipe est sympa et fait de son mieux pour que l'on se sente bien et que l'on sorte avec le sourire, ce qui est toujours mon cas! Merci l'équipe et à bientôt !",
    postedAt: "il y a un an",
  },
  {
    id: "clemence-eisenberg",
    name: "Clémence Eisenberg",
    imagePath: clemenceeisenberg.src,
    review:
      "La responsable Morgane a été parfaite, de l'essai coiffure au jour J pour mon mariage à la mairie. La coiffure répondait totalement à mes attentes et Morgane est même venue plus tôt le jour J pour que je sois à l'heure à la mairie !! ;) Salon très professionnel, toutes les coiffeuses sont souriantes, de bonne humeur, ce fut un plaisir ! Je reviendrai et je recommande totalement !!!!",
    postedAt: "il y a 5 ans",
  },
];
