import pastwork1 from "@/assets/images/prestations/pastwork/past-work1.png";
import pastwork2 from "@/assets/images/prestations/pastwork/past-work2.png";
import pastwork3 from "@/assets/images/prestations/pastwork/past-work3.png";
import pastwork4 from "@/assets/images/prestations/pastwork/past-work4.png";
import pastwork5 from "@/assets/images/prestations/pastwork/past-work5.png";
import pastwork6 from "@/assets/images/prestations/pastwork/past-work6.png";
import pastwork7 from "@/assets/images/prestations/pastwork/past-work7.png";
import pastwork8 from "@/assets/images/prestations/pastwork/past-work8.png";
import pastwork9 from "@/assets/images/prestations/pastwork/past-work9.png";
import pastwork10 from "@/assets/images/prestations/pastwork/past-work10.png";

export const pastWorkImages = [
  { src: pastwork1, alt: "Soin Balinet - Avant/Après" },
  { src: pastwork2, alt: "Chevelure lumineuse après soin" },
  { src: pastwork3, alt: "Massage du cuir chevelu" },
  { src: pastwork4, alt: "Résultat final, coupe élégante" },
  { src: pastwork5, alt: "Application de produits nourrissants" },
  { src: pastwork6, alt: "Coiffure soignée et brillante" },
  { src: pastwork7, alt: "Texture sublimée" },
  { src: pastwork8, alt: "Cheveux en pleine santé" },
  { src: pastwork9, alt: "Préparation du soin personnalisé" },
  { src: pastwork10, alt: "Finitions professionnelles" },
];
