import { CollectionData } from "./collections";
// import collectionsBanner from "@/assets/images/banners/collections-banner.png";

import darkgrey1 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-01.jpg";
import darkgrey2 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-02.jpg";
import darkgrey3 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-03.jpg";
import darkgrey4 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-04.jpg";
import darkgrey5 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-05.jpg";
import darkgrey6 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-06.jpg";
import darkgrey7 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-07.jpg";
import darkgrey8 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-08.jpg";
import darkgrey9 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-09.jpg";
import darkgrey10 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-10.jpg";
import darkgrey11 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-11.jpg";
import darkgrey12 from "/public/images/collections/2021-dark-grey/ALEXANDRA-GREY-DARK-GREY-12.jpg";

export const darkGreyDescriptionParagraphs = [
  "La collection Dark Grey incarne le mariage ultime entre mystère et élégance. Chaque pièce de cette collection joue avec les contrastes du noir profond et du blanc lumineux, créant une tension visuelle captivante.",
  "Les coupes classiques sont sublimées par des détails modernes et des matières luxueuses, offrant une allure sophistiquée et intemporelle. Chaque silhouette révèle une histoire de pouvoir et de délicatesse.",
  "Cette collection se positionne comme un hommage à l'élégance audacieuse, transformant des inspirations intemporelles en pièces avant-gardistes, parfaitement adaptées à une femme ou un homme sûr(e) de soi et de son style.",
];

export const darkGreyCollection: CollectionData = {
  id: "2021-dark-grey",
  title: "Collection Dark Grey",
  descriptionParagraphs: darkGreyDescriptionParagraphs,
  images: [
    darkgrey1,
    darkgrey2,
    darkgrey3,
    darkgrey4,
    darkgrey5,
    darkgrey6,
    darkgrey7,
    darkgrey8,
    darkgrey9,
    darkgrey10,
    darkgrey11,
    darkgrey12,
  ],
  date: "11/21",
  bannerImage: darkgrey1,
};
