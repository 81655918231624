import Link from "next/link";

export const SeoSection = () => {
  return (
    <section className="flex w-full flex-col items-center justify-center bg-gradient-to-b from-gray-50 to-white px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <div className="flex flex-col items-center justify-center">
        <div className="mb-16 text-center">
          <span className="text-md mb-4 block uppercase tracking-[0.2em] text-gray-500">
            4 salons · 1 vision
          </span>
          <h2 className="font-cormorant text-5xl uppercase tracking-wide text-primary ph:text-6xl sm:text-7xl lg:text-8xl">
            Coiffure & Visagiste
          </h2>
          <span className="mt-2 block font-pencerio text-4xl text-gold sm:text-5xl md:text-6xl lg:text-7xl">
            Alexandra Grey
          </span>
        </div>

        <div className="prose prose-sm mx-auto flex flex-col items-center justify-center text-gray-600">
          {/* Paragraphe d'intro SEO */}
          <p className="mb-12 max-w-4xl text-center text-base leading-relaxed ph:text-lg">
            Vous recherchez un <strong>salon de coiffure</strong> de confiance
            en Île-de-France ? Bienvenue chez <strong>Alexandra Grey</strong>,
            votre référence pour sublimer vos cheveux. Présents à{" "}
            <strong>Villejuif</strong>, <strong>Choisy-le-Roi</strong> et{" "}
            <strong>Ville d&apos;Avray</strong>, nous proposons des prestations
            haut de gamme pour tous vos besoins, dont notre salon-concept unique{" "}
            <em>“Au grey des couleurs”</em> à Villejuif.
          </p>

          <div className="mb-12 grid max-w-screen-xl gap-12 md:grid-cols-2">
            {/* Bloc Emplacements */}
            <div className="relative border border-gray-100 bg-white p-8 shadow-sm">
              <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-white px-4">
                <h3 className="whitespace-nowrap text-xl font-medium text-primary ph:text-2xl">
                  Nos Emplacements
                </h3>
              </div>
              <p className="mt-4 text-base leading-relaxed text-gray-600 ph:text-lg">
                Envie d&apos;une coupe tendance ou d&apos;une coloration experte
                ? Visitez nos
                <strong> salons de coiffure à Villejuif</strong> - dont “Au grey
                des couleurs” au cœur de la ville près de Louis Aragon -, à{" "}
                <strong>Choisy-le-Roi</strong>
                ou à <strong>Ville d&apos;Avray</strong>. Profitez de notre
                savoir-faire reconnu pour une expérience coiffure inoubliable.
              </p>
            </div>

            {/* Bloc Services */}
            <div className="relative border border-gray-100 bg-white p-8 shadow-sm">
              <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-white px-4">
                <h3 className="whitespace-nowrap text-xl font-medium text-primary ph:text-2xl">
                  Nos Services
                </h3>
              </div>
              <p className="mt-4 text-base leading-relaxed text-gray-600 ph:text-lg">
                Notre équipe de <strong>coiffeurs-visagistes</strong> est
                spécialisée dans la <strong>coiffure femme</strong>, la
                consultation visagiste, les coiffures de mariage et tous vos
                événements. Nous utilisons des techniques de coloration avancées
                (dont <em>Olaplex</em>) pour préserver la santé de vos cheveux.
                Que vous souhaitiez un look naturel, un relooking total ou un
                simple moment de détente, nous sommes là pour révéler votre
                personnalité.
              </p>
            </div>
          </div>

          <div className="mt-16 text-center">
            <Link
              href="/reservation"
              className="group relative inline-block overflow-hidden border border-primary bg-white px-8 py-3 text-base font-medium text-primary transition-colors ph:text-lg"
            >
              <span className="relative z-10 transition-colors group-hover:text-white">
                Réserver dans un salon de coiffure
              </span>
              <div className="absolute bottom-0 left-0 h-full w-0 bg-primary transition-all duration-300 ease-out group-hover:w-full"></div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
