import marriageBanner from "@/assets/images/prestations/privatisation-lieu.jpg";
import salonVilleDAvray from "@/assets/images/prestations/salon-ville-d-avray.png";
import { type ServiceSection } from "../types";
import { pastWorkImages } from "./past-work-images";

export const privatisationService: ServiceSection = {
  id: "privatisation-lieu",
  bannerImage: marriageBanner,
  bannerTitle: "Privatisation Salon de Coiffure",
  keywords: [
    "privatisation salon villejuif",
    "salon privé ville d'avray",
    "evjf coiffure val de marne",
    "privatisation salon 94800",
    "location salon coiffure 92410",
    "événement privé coiffure",
  ],
  firstSection: {
    imagePath: salonVilleDAvray.src,
    title:
      "Privatisation Salon de Coiffure à Villejuif (94) et Ville d'Avray (92)",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Privatisez nos salons de coiffure à Villejuif (Val-de-Marne) ou Ville d'Avray (Hauts-de-Seine). Alexandra Grey vous offre des espaces élégants et intimistes pour vos événements privés : EVJF, anniversaires, séances photos...",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Dans nos salons Alexandra Grey en Ile de France, profitez d'un lieu élégant et haut de gamme, réservé rien que pour vous. L'occasion de vivre une expérience unique, que ce soit entre amis ou en famille.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Notre promesse", isBold: true },
          {
            text: " : un moment inoubliable dans un cadre raffiné, propice au partage et à la convivialité.",
          },
        ],
      },
    ],
  },
  secondSection: {
    title: "Nos prestations de Privatisation",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Nous vous accompagnons dans la création d'un événement sur mesure. Du choix des prestations (coiffure, maquillage, soins) à l'agencement de l'espace, tout est pensé pour répondre à vos attentes.",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "Les points clés :" }],
        items: [
          "Écoute de vos besoins : définir le thème, l'ambiance, les services souhaités",
          "Organisation complète : mise à disposition du salon, des équipements et des professionnels",
          "Expérience sur mesure : moments de détente, ateliers beauté, conseils personnalisés",
        ],
      },
    ],
  },
  thirdSection: {
    title: "Pourquoi privatiser un salon ?",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "La privatisation de notre salon vous permet de créer un événement hors du commun, loin des lieux habituels. L'atmosphère intime, les services personnalisés et le cadre élégant en font une expérience mémorable.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour un enterrement de vie de jeune fille, surprenez la future mariée avec un espace entièrement dédié, des soins et conseils, dans une ambiance détendue et festive.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour d'autres occasions, anniversaires, réunions entre amis, séances de beauté privées... tout est possible. Notre équipe est là pour concrétiser vos envies.",
          },
        ],
      },
    ],
  },
  fourthSection: {
    title: "TRAVAUX ANTÉRIEURS",
    images: pastWorkImages,
  },
};
