import { CollectionData } from "./collections";

import sixty19 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-19.jpg";
import sixty20 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-20.jpg";
import sixty21 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-21.jpg";
import sixty22 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-22.jpg";
import sixty23 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-23.jpg";
import sixty24 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-24.jpg";
import sixty25 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-25.jpg";
import sixty26 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-26.jpg";
import sixty27 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-27.jpg";
import sixty28 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-28.jpg";
import sixty29 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-29.jpg";
import sixty30 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-30.jpg";
import sixty31 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-31.jpg";
import sixty32 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-32.jpg";
import sixty33 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-33.jpg";
import sixty34 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-34.jpg";
import sixty35 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-35.jpg";
import sixty36 from "/public/images/collections/2023-60s-grey/ALEXANDRA-GREY-60S-GREY-36.jpg";

export const sixtyGreyDescriptionParagraphs = [
  "Plongeant dans l'énergie vibrante des années 60, cette collection capture l'essence rétro avec des teintes douces et des motifs audacieux. Chaque création revisite les classiques vintage tout en leur apportant une touche contemporaine.",
  "Les silhouettes emblématiques des sixties se mêlent à des coupes modernes, incarnant une élégance décontractée. Les textures légères et les motifs graphiques insufflent une fraîcheur qui célèbre l'esprit de liberté de cette époque.",
  "Cette série illustre une parfaite harmonie entre nostalgie et modernité, où chaque pièce invite à revivre une époque audacieuse tout en restant ancrée dans le luxe d'aujourd'hui.",
];

export const sixtyGreyCollection: CollectionData = {
  id: "2023-60s-grey",
  title: "Collection 60's Grey",
  descriptionParagraphs: sixtyGreyDescriptionParagraphs,
  images: [
    sixty19,
    sixty20,
    sixty21,
    sixty22,
    sixty23,
    sixty24,
    sixty25,
    sixty26,
    sixty27,
    sixty28,
    sixty29,
    sixty30,
    sixty31,
    sixty32,
    sixty33,
    sixty34,
    sixty35,
    sixty36,
  ],
  date: "04/23",
  bannerImage: sixty19,
};
