"use client";

import { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ReviewData, reviewsData } from "./reviews";
import { ICStar } from "@/assets/icons/ic-star";
import { ICArrowLeft } from "@/assets/icons/ph-arrow-left";
import { ICArrowRight } from "@/assets/icons/ph-arrow-right";
import Image from "next/image";

export const ReviewsSection = () => {
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isMobile, setIsMobile] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 640;
      setIsMobile(newIsMobile);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = useCallback(() => {
    setIsAnimating(true);
    setDirection(1);

    if (isMobile) {
      setCurrentPairIndex((prev) => {
        const newIndex = prev === reviewsData.length - 1 ? 0 : prev + 1;
        return newIndex;
      });
    } else {
      setCurrentPairIndex((prev) => {
        const newIndex = prev >= reviewsData.length - 2 ? 0 : prev + 2;
        return newIndex;
      });
    }
  }, [isMobile]);

  const handlePrevious = useCallback(() => {
    setIsAnimating(true);
    setDirection(-1);

    if (isMobile) {
      setCurrentPairIndex((prev) => {
        const newIndex = prev === 0 ? reviewsData.length - 1 : prev - 1;
        return newIndex;
      });
    } else {
      setCurrentPairIndex((prev) => {
        const newIndex =
          prev === 0 ? Math.floor((reviewsData.length - 1) / 2) * 2 : prev - 2;
        return newIndex;
      });
    }
  }, [isMobile]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (!isPaused) {
      timer = setInterval(() => {
        handleNext();
      }, 5000);
    } else {
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [currentPairIndex, isPaused, handleNext]);

  const getCurrentReviews = () => {
    if (isMobile) {
      return [reviewsData[currentPairIndex]];
    }
    return [
      reviewsData[currentPairIndex],
      reviewsData[currentPairIndex + 1] || reviewsData[0],
    ];
  };

  const ReviewCard = ({ review }: { review: ReviewData }) => (
    <div
      className="flex h-full flex-col justify-between bg-primary p-4 text-white sm:h-[400px] sm:p-6"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {/* Top section */}
      <div className="flex h-full flex-col">
        <div className="mb-3 flex sm:mb-4">
          {[...Array(5)].map((_, i) => (
            <ICStar key={i} className="h-4 w-4 text-yellow-400 sm:h-5 sm:w-5" />
          ))}
        </div>

        {/* Review text section avec ellipsis vertical */}
        <div className="flex-grow">
          <p className="line-clamp-[12] text-xs leading-relaxed sm:text-sm">
            {review.review}
          </p>
        </div>
      </div>

      {/* Bottom section */}
      <div>
        <div className="my-3 h-[1px] w-full bg-white opacity-30 sm:my-4"></div>

        <div className="flex items-center gap-2 sm:gap-3">
          <div className="h-8 w-8 overflow-hidden rounded-full bg-white/10 sm:h-10 sm:w-10">
            {review.imagePath ? (
              <Image
                src={review.imagePath}
                alt={review.name}
                width={40}
                height={40}
                className="h-full w-full object-cover"
              />
            ) : (
              <div className="flex h-full w-full items-center justify-center text-base sm:text-lg">
                {review.name[0]}
              </div>
            )}
          </div>
          <div className="overflow-x-hidden">
            <p className="truncate text-sm font-medium sm:text-base">
              {review.name}
            </p>
            <p className="truncate text-xs text-gray-400 sm:text-sm">
              {review.postedAt}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  // px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96
  return (
    <div className="grid w-full gap-6 bg-tertiary px-4 py-20 ph:px-24 sm:gap-8 md:grid-cols-12 md:px-48 lg:px-72 xl:px-96">
      {/* Left section */}
      <div className="flex flex-col justify-between gap-6 md:col-span-4 lg:gap-4 2xl:gap-6">
        <div className="mb-4 md:mb-0">
          <h2 className="mb-12 font-cormorant text-4xl ph:text-5xl sm:text-6xl lg:mb-16 lg:text-7xl">
            AVIS
          </h2>
          <div className="max-w-[400px]">
            <p className="mb-6 text-base sm:text-lg">
              Voici ce que disent nos clientes de nous !<br />
              Vous aussi, laissez nous votre avis :
            </p>
            <a
              href="https://g.page/r/CU5VsVTL7_XJEAE/review"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-block overflow-hidden border border-black px-8 py-2"
            >
              <span className="relative z-10 transition-colors group-hover:text-white">
                Laisser un avis
              </span>
              <div className="absolute bottom-0 left-0 h-full w-0 bg-primary transition-all duration-300 ease-out group-hover:w-full"></div>
            </a>
          </div>
        </div>

        <div
          className="flex gap-4"
          onMouseEnter={() => {
            setIsPaused(true);
          }}
          onMouseLeave={() => {
            setIsPaused(false);
          }}
        >
          <button
            onClick={handlePrevious}
            title="Précédent"
            className="rounded-full border border-black p-4 transition-colors hover:bg-primary hover:text-white"
            disabled={isAnimating}
          >
            <ICArrowLeft className="h-6 w-6" />
          </button>
          <button
            onClick={handleNext}
            title="Suivant"
            className="rounded-full border border-black p-4 transition-colors hover:bg-primary hover:text-white"
            disabled={isAnimating}
          >
            <ICArrowRight className="h-6 w-6" />
          </button>
        </div>
      </div>

      {/* Right section */}
      <div className="relative md:col-span-8">
        <div className="overflow-hidden">
          <AnimatePresence mode="wait" custom={direction}>
            <motion.div
              key={currentPairIndex}
              custom={direction}
              initial={{ opacity: 0, x: direction * 200 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: direction * -200 }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              onAnimationComplete={() => setIsAnimating(false)}
              className="grid gap-4 sm:grid-cols-2"
            >
              {getCurrentReviews().map((review, index) => (
                <div
                  key={`${currentPairIndex}-${index}`}
                  className={`w-full ${
                    index === 0 ? "justify-self-end" : "justify-self-start"
                  }`}
                >
                  <ReviewCard review={review} />
                </div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
